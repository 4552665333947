import { useOnOutsideEvent } from "assets";
import { Scroller } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./filtered-content.module.scss";

const FilteredContent = ({
  children,
  contentLength,
  contentType,
  filterTagList1,
  filterTagList2,
  setParentTag1,
  setParentTag2,
}) => {
  const [showFilterList, setShowFilterList] = useState(false);
  const [filterTag1, setFilterTag1] = useState(null);
  const [filterTag2, setFilterTag2] = useState(null);
  const [filterListOffset, setFilterListOffset] = useState(42);

  const filterBoxRef = useRef(null);
  const filterListInnerContainer = useRef();

  const ifParentTag2 = (tag) => {
    if (typeof setParentTag2 === "function") {
      setParentTag2(tag);
    }
  };

  const singularContentType =
    contentType === "stories"
      ? "Story"
      : contentType === "resources"
        ? "Resource"
        : "Item";

  const displayFilterTags = () => {
    return (
      <div className={"container-fluid"}>
        <div className={styles.filterBoxContainer}>
          <div className={styles.filterBox}>
            <span id={"filter-by"}>Filter by</span>
            <div
              aria-expanded={showFilterList}
              aria-labelledby={"filter-by"}
              className={styles.filterSelect}
              onClick={(e) => {
                if (
                  e.currentTarget.contains(e.target) &&
                  !e.target.classList.contains(styles.closeIcon) &&
                  !document
                    .getElementsByClassName(styles.filterList)[0]
                    .contains(e.target)
                ) {
                  setShowFilterList(!showFilterList);
                  // forces scroll bar to size to correct height on mobile
                  setTimeout(function () {
                    window.dispatchEvent(new Event("resize"));
                  }, 100);
                }
              }}
              onKeyDown={(e) => {
                if (
                  (e.key === "Enter" || e.key === "Space") &&
                  !e.target.classList.contains(styles.closeIcon)
                ) {
                  setShowFilterList(!showFilterList);
                  // forces scroll bar to size to correct height on mobile
                  setTimeout(function () {
                    window.dispatchEvent(new Event("resize"));
                  }, 100);
                }
              }}
              ref={filterBoxRef}
              tabIndex={0}
            >
              <div className={styles.innerFilterBox}>
                <span>
                  {filterTag1 || filterTag2 || "All"}
                  <i
                    aria-label={"Close"}
                    className={classNames("icon-close", styles.closeIcon)}
                    hidden={filterTag1 || filterTag2 ? false : true}
                    onClick={() => {
                      setFilterTag1("");
                      setParentTag1("");
                      setFilterTag2("");
                      ifParentTag2("");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Space") {
                        setFilterTag1("");
                        setParentTag1("");
                        setFilterTag2("");
                        ifParentTag2("");
                        document
                          .getElementsByClassName(styles.filterSelect)[0]
                          .focus();
                      }
                    }}
                    tabIndex={0}
                  ></i>
                </span>
                <i
                  className={classNames("icon-forward", styles.carrot, {
                    [styles.openFilter]: showFilterList,
                  })}
                />
              </div>
              <div
                className={classNames(styles.filterList, {
                  [styles.showFilterList]: showFilterList,
                })}
                style={{ top: filterListOffset }}
              >
                <div
                  className={styles.filterListInnerContainer}
                  ref={filterListInnerContainer}
                >
                  <div
                    className={classNames(styles.filterTags, {
                      [styles.topicTags]: filterTagList1[0] === "Topics",
                      [styles.exhibitTags]: filterTagList1[0] === "Exhibits",
                    })}
                  >
                    <div className={styles.groupTitle}>{filterTagList1[0]}</div>
                    <ul>
                      {filterTagList1[1]?.map((tag) => {
                        return (
                          <li
                            aria-label={tag?.title}
                            className={classNames({
                              [styles.activeTag]: filterTag1 === tag?.title,
                            })}
                            key={tag?.id}
                            onClick={() => {
                              setShowFilterList(
                                filterTag1 !== tag?.title ? false : true
                              );
                              setFilterTag1(
                                filterTag1 !== tag?.title ? tag?.title : ""
                              );
                              setFilterTag2("");
                              setParentTag1(
                                filterTag1 !== tag?.title ? tag?.title : ""
                              );
                              ifParentTag2("");
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === "Space") {
                                setShowFilterList(
                                  filterTag1 !== tag?.title ? false : true
                                );
                                setFilterTag1(
                                  filterTag1 !== tag?.title ? tag?.title : ""
                                );
                                setFilterTag2("");
                                setParentTag1(
                                  filterTag1 !== tag?.title ? tag?.title : ""
                                );
                                ifParentTag2("");
                                document
                                  .getElementsByClassName(
                                    styles.filterSelect
                                  )[0]
                                  .focus();
                              }
                            }}
                            tabIndex={0}
                          >
                            {tag?.title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {filterTagList2 && (
                    <div
                      className={classNames(
                        styles.filterTags,
                        styles.periodTags
                      )}
                    >
                      <div className={styles.groupTitle}>
                        {filterTagList2[0]}
                      </div>
                      <ul>
                        {filterTagList2[1]?.map((tag) => {
                          return (
                            <li
                              aria-label={tag?.title}
                              className={classNames({
                                [styles.activeTag]: filterTag2 === tag?.title,
                              })}
                              key={tag?.id}
                              onClick={() => {
                                setShowFilterList(
                                  filterTag2 !== tag?.title ? false : true
                                );
                                setFilterTag2(
                                  filterTag2 !== tag?.title ? tag?.title : ""
                                );
                                setFilterTag1("");
                                ifParentTag2(
                                  filterTag2 !== tag?.title ? tag?.title : ""
                                );
                                setParentTag1("");
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                  setShowFilterList(
                                    filterTag2 !== tag?.title ? false : true
                                  );
                                  setFilterTag2(
                                    filterTag2 !== tag?.title ? tag?.title : ""
                                  );
                                  setFilterTag1("");
                                  ifParentTag2(
                                    filterTag2 !== tag?.title ? tag?.title : ""
                                  );
                                  setParentTag1("");
                                  document
                                    .getElementsByClassName(
                                      styles.filterSelect
                                    )[0]
                                    .focus();
                                }
                              }}
                              tabIndex={0}
                            >
                              <span>{tag?.date}</span> {tag?.displayTitle}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div className={styles.scrollContainer}>
                  <Scroller
                    className={"hidden-mobile"}
                    direction="vertical"
                    ref={filterListInnerContainer}
                    scrollBarColor={"light"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.storyCount}>
            {contentLength}{" "}
            {contentLength > 1
              ? contentType
                ? contentType.charAt(0).toUpperCase() + contentType.slice(1)
                : "Items"
              : singularContentType}
          </div>
        </div>
      </div>
    );
  };

  useOnOutsideEvent(filterBoxRef, () => setShowFilterList(false));

  useEffect(() => {
    setFilterListOffset(filterBoxRef?.current.clientHeight + 7);
  });

  return (
    <div data-testid="filtered-content">
      {displayFilterTags()}
      {children}
    </div>
  );
};

FilteredContent.propTypes = {
  children: PropTypes.node,
  contentLength: PropTypes.number,
  contentType: PropTypes.string,
  filterTagList1: PropTypes.array,
  filterTagList2: PropTypes.array,
  setParentTag1: PropTypes.func,
  setParentTag2: PropTypes.func,
};

FilteredContent.defaultProps = {};

export default FilteredContent;
