import { setTagList } from "assets";
import { FilteredContent } from "organisms";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ResourceTopicContent = ({ content, tagData }) => {
  const [exhibitTag, setExhibitTag] = useState(null);

  const exhibitTagList = setTagList("exhibit", tagData, content);

  let activeSection = true;
  let itemCount = 0;

  const displayedContent = [];

  content?.map((item) => {
    if (item.props.exhibit?.title) {
      // Resource Banner
      activeSection = !exhibitTag || exhibitTag === item.props.exhibit.title;
      activeSection ? displayedContent.push(item) : null;
    } else if (item.props.children?.length) {
      //Card Wrapper
      if (activeSection) {
        itemCount += item.props.children.length;
        displayedContent.push(item);
      }
    } else {
      // Everything else
      if (activeSection) {
        itemCount++;
        displayedContent.push(item);
      }
    }
  });

  return (
    <div data-testid="resource-topic-content">
      <FilteredContent
        contentLength={itemCount}
        contentType={"resources"}
        filterTagList1={["Exhibits", exhibitTagList]}
        setParentTag1={setExhibitTag}
      >
        {displayedContent}
      </FilteredContent>
    </div>
  );
};

ResourceTopicContent.propTypes = {
  content: PropTypes.object,
  tagData: PropTypes.array,
};

export default ResourceTopicContent;
